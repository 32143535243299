<template>
  <nh-dialog
    v-model="visible"
    :title="`打印货品标签-${PRINT_LABEL_ENUM[titleVlaue]}`"
    :track="{
      name_zh: `${nameZh}抽屉-详情/弹窗-打印货品标签`,
      trackName: $route.path
    }"
    @close="handleBeforeClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="form"
      label-width="100px"
    >
      <nh-dialog-form-item
        label="标签维度："
        prop="labelRadio"
        :span="24"
      >
        <el-radio-group v-model="form.labelRadio" @change="handleChangeFormData">
          <el-radio
            v-for="item in labelRadio(titleVlaue)"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        v-if="form.labelRadio === DICTIONARY.PRODUCT_CODES"
        label="条码内容："
        :span="24"
      >
        货品编码
      </nh-dialog-form-item>
      <nh-dialog-form-item
        v-if="form.labelRadio === DICTIONARY.BOX_CODES"
        label="条码格式："
        :span="24"
      >
        货品编码+年月日+6位流水号
      </nh-dialog-form-item>
      <!-- 货品批次码 -->
      <template
        v-if="form.labelRadio === DICTIONARY.BATCH_CODES"
      >
        <el-row v-for="(item, index) in form.tableList" :key="index">
          <nh-dialog-form-item
            label="批次数："
            :span="24"
            :prop="'tableList.'+ index+ '.count'"
            :rules="[
              {required: true, message: '不能为空', trigger: 'blur'},
              {validator: validateNum, trigger: 'blur' }
            ]"
          >
            <el-input v-model.number="item.count" @input="(val) => handleChangeCount(val, index)" />
          </nh-dialog-form-item>
          <el-col
            v-for="(print, printIndex) in item.printBatchNum"
            :key="printIndex"
            :span="24"
          >
            <nh-dialog-form-item
              :label="print.label"
              :span="10"
            >
              货品编码+年月日+6位流水号
            </nh-dialog-form-item>
            <nh-dialog-form-item
              label="打印份数："
              :span="10"
              :prop="'tableList.'+ index+ '.printBatchNum.'+ printIndex+'.value'"
              :rules="[
                {required: true, message: '不能为空', trigger: 'blur'},
                {validator: validateNum, trigger: 'blur' }
              ]"
            >
              <el-input v-model.number="print.value" />
            </nh-dialog-form-item>
          </el-col>
        </el-row>
      </template>
      <!-- 货品码 -->
      <template v-else-if="form.labelRadio === DICTIONARY.PRODUCT_CODES">
        <nh-dialog-form-item
          label="打印份数："
          :span="24"
          prop="tableList[0].printNum"
          :rules="[
            {required: true, message: '不能为空', trigger: 'blur'},
            {validator: validateNum, trigger: 'blur' }
          ]"
        >
          <el-input
            v-if="titleVlaue === DICTIONARY.SINGLE"
            v-model.number="form.tableList[0].printNum"
          />
          <el-radio-group v-model="form.printType" @change="handleChangeFormData">
            <el-radio
              v-for="item in printType"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </nh-dialog-form-item>
      </template>
      <!-- 箱条码 -->
      <template v-else-if="form.labelRadio === DICTIONARY.BOX_CODES">
        <nh-dialog-form-item
          label="打印份数："
          :span="24"
          prop="tableList[0].count"
          :rules="[
            {required: true, message: '不能为空', trigger: 'blur'},
            {validator: validateNum, trigger: 'blur' }
          ]"
        >
          <el-input
            v-if="titleVlaue === DICTIONARY.SINGLE"
            v-model.number="form.tableList[0].count"
          />
          <el-radio-group v-model="form.printType" @change="handleChangeFormData">
            <el-radio
              v-for="item in printType"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </nh-dialog-form-item>
      </template>
    </nh-dialog-form>
    <PlTable
      v-if="titleVlaue === DICTIONARY.MORE"
      :data="form.tableList"
      :columns="TABLECOLUMN"
    >
      <template #printBatchNum="{row}">
        <el-input
          v-if="form.labelRadio === DICTIONARY.PRODUCT_CODES"
          v-model.number="row.printNum"
        />
        <el-input
          v-else-if="form.labelRadio === DICTIONARY.BOX_CODES"
          v-model.number="row.count"
        />
      </template>
    </PlTable>
    <template #footer>
      <div>
        <nh-button
          type="primary"
          @click="handleSubmit('editForm')"
        >
          确  认
        </nh-button>
        <nh-button
          type="grey"
          @click="handleClose"
        >
          关 闭
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import {
  DICTIONARY, printLabel, labelRadio, printType,
  PRINT_LABEL_ENUM,
} from './constant';
import { TABLECOLUMN } from './fileds';

export default {
  name: 'InboundOrderDetailTableGoodsLabel',
  inject: ['nameZh'],
  data() {
    const validateStart = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    };
    return {
      DICTIONARY,
      printLabel,
      labelRadio,
      printType,
      PRINT_LABEL_ENUM,
      TABLECOLUMN,
      visible: false,
      titleVlaue: '',
      form: {
        labelRadio: DICTIONARY.PRODUCT_CODES,
        printType: DICTIONARY.APPOINT_BOX,
        tableList: [],
      },
      rules: {
        batchNum: [
          { required: true, validator: validateStart, trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    validateNum(rule, value, callback) {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    },
    handleChangeCount(n, index) {
      let printBatchNum = [{ label: '条码1:', value: null }];
      const num = Number(n);
      if (num) {
        printBatchNum = [...new Array(num)].map((item, i) => ({
          label: `条码:${i + 1}`, value: null,
        }));
      }
      this.form.tableList[index].printBatchNum = printBatchNum;
    },
    init(list) {
      this.visible = true;
      const tableList = list.map((item) => ({
        ...item,
        count: 1,
        printBatchNum: [{ label: '条码1:', value: null }], // 货品批次码打印次数
        printNum: this.setPrintNum(item) || 1,
      }));
      this.form = {
        ...this.form,
        tableList,
      };
      this.titleVlaue = list.length > 1 ? DICTIONARY.MORE : DICTIONARY.SINGLE;
    },
    // 标签或打印方式改变
    handleChangeFormData() {
      switch (this.form.labelRadio) {
        case DICTIONARY.PRODUCT_CODES: // 货品码
          this.form.tableList = this.form.tableList.map((item) => ({
            ...item,
            count: 1,
            printNum: this.setPrintNum(item) || 1,
          }));
          break;
        case DICTIONARY.BOX_CODES:
          this.form.tableList = this.form.tableList.map((item) => ({
            ...item,
            count: this.setPrintNum(item) || 1,
            printNum: null,
          }));
          break;
        default:
          this.form.tableList = this.form.tableList.map((item) => ({
            ...item,
            count: 1,
            printNum: null,
          }));
      }
    },
    setPrintNum(row) {
      return this.form.printType === DICTIONARY.APPOINT_BOX
        ? this.getMinNum(row.bookingNum, row.conversionRate)
        : Math.ceil(this.getMaxNum(row.bookingNum, row.conversionRate) / row.veneerNum);
    },
    getMinNum(num, rate) {
      if (num && rate) {
        return Math.floor(num / rate);
      }
      return null;
    },
    getMaxNum(num, rate) {
      if (num && rate) {
        return Math.ceil(num / rate);
      }
      return null;
    },
    async handleSubmit() {
      await this.$refs.ruleForm.validate();
      if (!this.validatePrintNum()) {
        this.$message.error('打印份数不能为空');
      } else {
        const routeUrl = this.$router.resolve({
          path: '/print/goods-label',
          query: { res: JSON.stringify(this.form) },
        });
        window.open(routeUrl.href, '_blank');
        this.handleClose();
      }
    },
    validatePrintNum() {
      let flag = true;
      this.form.tableList.forEach((item) => {
        if (this.form.labelRadio === DICTIONARY.BOX_CODES && !item.count) {
          flag = false;
        } else if (this.form.labelRadio === DICTIONARY.PRODUCT_CODES && !item.printNum) {
          flag = false;
        }
      });
      return flag;
    },
    handleClose() {
      this.visible = false;
    },
    handleBeforeClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
