import { StoreBillTypeText } from '@/constant/task';
import { timeFormat } from '../utils';
import { URGENT_ENUM } from '../constant';

const DetailFromInfo = (isShowDock, { handling }) => {
  const arr = handling ? [{
    label: '是否需要装卸:',
    prop: 'handling',
    formatter: (cellValue) => URGENT_ENUM[cellValue],
  }, {
    label: '是否外包装卸:',
    prop: 'outsourceHandling',
    formatter: (cellValue) => URGENT_ENUM[cellValue],
  }] : [{
    label: '是否需要装卸:',
    prop: 'handling',
    formatter: (cellValue) => URGENT_ENUM[cellValue],
  }];
  const showDock = isShowDock ? [...arr] : [];
  return [{
    label: '入库单号:',
    prop: 'receiptOrderNo',
  }, {
    label: '货主名称:',
    prop: 'shipperName',
  }, {
    label: '入库单类型:',
    prop: 'receiptType',
    formatter: (cellValue) => StoreBillTypeText[cellValue],
  }, {
    label: '车牌号:',
    prop: 'plateNo',
  }, {
    label: '货主订单号:',
    prop: 'customerOrderNo',
  }, {
    label: '外部单号:',
    prop: 'externalOrderNo',
  }, {
    label: '预约送货时间:',
    prop: 'bookingTime',
    formatter: timeFormat,
  }, {
    label: '供应商:',
    prop: 'supplierName',
  },
  ...showDock];
};
export default DetailFromInfo;
