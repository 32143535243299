import unitNumber from '@/utils/unitNumber';

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  width: 180,
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 180,
}, {
  label: '换算关系',
  prop: 'conversionRate',
  minWidth: 80,
}, {
  label: '单板数量',
  prop: 'veneerNum',
  minWidth: 120,
}, {
  label: '预约数量',
  prop: 'bookingNumTxt',
  minWidth: 80,
}, {
  label: '打印份数',
  prop: 'printBatchNum',
  width: 120,
}];
export const MANUAL_TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  },
  {
    label: '指定库位',
    prop: 'assignedLocationCode',
    minWidth: 120,
  }, {
    label: '一次性容器码',
    prop: 'onceContainerCode',
    minWidth: 120,
  }, {
    label: '单板数量',
    prop: 'veneerNum',
    minWidth: 120,
  }, {
    label: '数量',
    prop: 'amount',
    minWidth: 120,
  }, {
    label: '操作',
    prop: 'action',
    minWidth: 80,
  }];
export const manualAssignmentFileds = [
  {
    label: '货主货品编码:',
    value: 'shipperGoodsCode',
    span: 8,
  },
  {
    label: '货品名称:',
    value: 'goodsName',
    span: 8,
  },
  {
    label: '货主:',
    value: 'shipperName',
    span: 8,
  },
  {
    label: '数量:',
    value: 'preAcceptanceNum',
    span: 5,
    formatter: (row) => (unitNumber.calculate({
      ...row,
      smallUnitNumber: row.preAcceptanceNum,
    }).txt),
  },
  {
    label: '已分配数量:',
    value: 'recommendedAmount',
    span: 5,
    formatter: (row) => (unitNumber.calculate({
      ...row,
      smallUnitNumber: row.recommendedAmount,
    }).txt),
  },
  {
    label: '单板个数:',
    value: 'singleVeneerNum',
    span: 5,
    formatter: (row) => (row.singleVeneerNum ? `${row.singleVeneerNum}板` : ''),
  },
  {
    label: '拣选面库位:',
    value: 'pickingLocationCodes',
    span: 5,
    formatter: (row) => (row.pickingLocationCodes ? row.pickingLocationCodes[0] : ''),
  },
];
