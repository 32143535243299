<template>
  <nh-drawer
    v-model="visible"
    title="人工分配库位"
    size="1111px"
    :before-close="handleClose"
    :track="{
      name_zh: `${nameZh}抽屉-详情/抽屉-人工分配库位`,
      trackName: $route.path
    }"
  >
    <nh-dialog-form
      ref="dialogForm"
      label-width="100px"
    >
      <nh-dialog-form-item
        v-for="(item,index) in manualAssignmentFileds"
        :key="index"
        :label="item.label"
        :span="item.span"
      >
        <span v-if="item.formatter">{{ item.formatter(manualRecommend) }}</span>
        <span v-else>{{ manualRecommend[item.value] }}</span>
      </nh-dialog-form-item>
    </nh-dialog-form>
    <el-form
      ref="selectForm"
      :model="form"
      label-width="100px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item
            label="选择库区:"
          >
            <el-select
              v-model="form.designatedZoneId"
              filterable
              @change="warehouseZoneChange"
            >
              <el-option
                v-for="(item, index) in warehouseZonelist"
                :key="index"
                :label="`${item.zoneCode} - ${item.zoneName}`"
                :value="item.id"
              >
                <div class="goods-attr-box">
                  <span class="goods-attr">{{ item.zoneCode }}</span>
                  <span class="goods-attr">{{ item.zoneName }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择巷道:">
            <el-select
              v-model="form.designatedRow"
              filterable
              @change="rowChange"
            >
              <el-option
                v-for="(item,index) in locationRowsList"
                :key="index"
                :label="`${item.zoneCode} - ${item.row}- ${item.emptyLocationCount}`"
                :value="item.row"
              >
                <div class="goods-attr-box">
                  <span class="goods-attr">{{ item.zoneCode }}</span>
                  <span class="goods-attr">{{ item.row }}</span>
                  <span class="goods-attr">{{ item.emptyLocationCount }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择层:">
            <el-select
              v-model="form.layer"
              filterable
              clearable
              @change="layerChange"
            >
              <el-option
                v-for="(item,index) in layerList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="form.storehouse.length>0?24:8">
          <el-form-item label="选择库位:">
            <el-select
              v-model="form.storehouse"
              filterable
              multiple
              @change="storehouseChange"
            >
              <el-option
                v-for="(item,index) in storehouseList"
                :key="index"
                :label="item.storageLocationCode"
                :value="item.storageLocationCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <PlTable
      :data="tableData"
      :columns="MANUAL_TABLECOLUMN"
      :border="true"
      :loading="loading.init"
    >
      <template #amount="{row}">
        <div class="enter-num-box">
          <el-input-number
            v-model="row.amount"
            :step="0.001"
            :precision="3"
            step-strictly
            :controls="false"
          />
          <span>{{ row.bigUnit }}</span>
        </div>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.onceContainerCode"
          type="text"
          @click="supplementaryPrinting(row)"
        >
          补打
        </nh-button>
        <nh-button
          class="delete-btn"
          type="text"
          :loading="loading.handleDelete"
          @click="handleDelete(row)"
        >
          删除
        </nh-button>
      </template>
    </PlTable>
    <template #footer>
      <div class="footer-box">
        <nh-button
          class="cloose-btn"
          @click="handleClose"
        >
          取消
        </nh-button>
        <nh-button
          type="primary "
          @click="handlePrint"
        >
          批量打印容器码
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import {
  manualRecommendQuery,
  warehouseZonePage,
  emptyLocationRows,
  queryLayer,
  storehouseQuery,
  locationAndContainerDelete,
  batchInsert,
  updateLocationAndContainer,
} from '../api';
import { STORE_UNIT } from './constant';
import { manualAssignmentFileds, MANUAL_TABLECOLUMN } from './fileds';

export default {
  name: 'ManualAssignment',
  mixins: [loadingMixin],
  inject: ['nameZh'],
  data() {
    return {
      visible: false,
      manualAssignmentFileds,
      MANUAL_TABLECOLUMN,
      STORE_UNIT,
      unitNumber,
      storeData: {},
      manualRecommend: {
        details: [],
      },
      rowNum: 0,
      locationRowsList: [],
      layerList: [],
      storehouseList: [],
      form: {
        storehouse: [],
      },
      loading: {
        init: false,
        handleDelete: false,
      },
      tableData: [],
      alreadyAdded: [],
    };
  },
  methods: {
    async init(row, storeData) {
      this.visible = true;
      this.storeData = storeData;
      const receiptOrderDetailIds = [row.id];
      this.manualRecommend = {};
      const result = await manualRecommendQuery({ receiptOrderDetailIds });
      this.manualRecommend = result[0] || { details: [] };
      this.tableData = this.manualRecommend.details.map((item) => ({
        ...item,
        shipperGoodsCode: this.manualRecommend.shipperGoodsCode,
        goodsName: this.manualRecommend.goodsName,
        shipperName: this.manualRecommend.shipperName,
        bigUnit: this.manualRecommend.bigUnit,
        smallUnit: this.manualRecommend.smallUnit,
        amount: item.amount / this.manualRecommend.conversionRate,
        conversionRate: this.manualRecommend.conversionRate,
      }));
      const smallUnitNumber = unitNumber.getTotal({
        ...this.manualRecommend, bigUnitNumber: this.manualRecommend.veneerNum,
      });
      this.rowNum = smallUnitNumber
        ? Math.ceil(this.manualRecommend.preAcceptanceNum / smallUnitNumber) : 0;
      this.getSelectList();
    },
    // 获取库区
    async getSelectList() {
      const resp = await warehouseZonePage({ page: 1, size: 1000 },
        {
          warehouseCode: this.storeData.warehouseCode,
          temperatureLayerCode: this.manualRecommend.temperatureLayerCode,
        });
      this.warehouseZonelist = resp.records;
    },
    // 库区改变
    async warehouseZoneChange() {
      this.locationRowsList = [];
      this.alreadyAdded = [];
      this.layerList = [];
      this.storehouseList = [];
      this.form.designatedRow = '';
      this.form.layer = '';
      this.form.storehouse = [];
      this.locationRowsList = await emptyLocationRows({
        zoneIds: [this.form.designatedZoneId],
      });
    },
    async rowChange() {
      this.layerList = [];
      this.alreadyAdded = [];
      this.storehouseList = [];
      this.form.layer = '';
      this.form.storehouse = [];
      const data = {
        warehouseCode: this.storeData.warehouseCode,
        zoneId: this.form.designatedZoneId,
        warehouseRow: this.form.designatedRow,
      };
      this.layerList = await queryLayer(data);
      this.storehouseList = await storehouseQuery({ ...data, isFilterEmptyLocations: true });
    },
    async layerChange() {
      this.alreadyAdded = [];
      this.storehouseList = [];
      this.form.storehouse = [];
      const data = {
        warehouseCode: this.storeData.warehouseCode,
        zoneId: this.form.designatedZoneId,
        warehouseRow: this.form.designatedRow,
        layer: this.form.layer || null,
        isFilterEmptyLocations: true,
      };
      this.storehouseList = await storehouseQuery(data);
    },
    storehouseChange(val) {
      if (this.rowNum <= this.tableData) {
        this.$message({ type: 'warning', message: '您不能再指定更多库位了！' });
        return;
      }
      if (this.alreadyAdded.length < val.length) {
        this.tableData.push({
          shipperGoodsCode: this.manualRecommend.shipperGoodsCode,
          goodsName: this.manualRecommend.goodsName,
          shipperName: this.manualRecommend.shipperName,
          veneerNum: this.manualRecommend.veneerNum,
          amount: this.manualRecommend.veneerNum,
          assignedLocationCode: val[val.length - 1],
          bigUnit: this.manualRecommend.bigUnit,
          smallUnit: this.manualRecommend.smallUnit,
          conversionRate: this.manualRecommend.conversionRate,
        });
      } else {
        const differentCode = this.alreadyAdded
          .concat(val).filter((v, i, arr) => arr.indexOf(v)
        === arr.lastIndexOf(v));
        const newArr = this.tableData.map((item) => item.assignedLocationCode);
        const index = newArr.lastIndexOf(differentCode[0]);
        this.tableData.splice(index, 1);
      }
      this.alreadyAdded = val;
    },
    handleClose() {
      this.form = {
        storehouse: [],
      };
      this.tableData = [];
      this.locationRowsList = [];
      this.alreadyAdded = [];
      this.layerList = [];
      this.storehouseList = [];
      this.visible = false;
    },
    async handleDelete(row) {
      if (row.id) {
        await locationAndContainerDelete({ recommendLocationAndContainerId: row.id });
      }
      this.tableData = this.tableData.filter(
        (item) => item !== row,
      );
    },
    // 批量打印
    async handlePrint() {
      let endCode = 0;
      const tableLists = [];
      this.tableData.forEach((item) => {
        if (!item.onceContainerCode) {
          endCode += 1;
        }
      });
      let resp = [];
      if (endCode) {
        resp = await batchInsert({
          prefix: '',
          containerType: 'ONCE',
          containerAttr: 'BELONG',
          batchMax: 999999,
          startCode: 1,
          blendedBatch: '是',
          endCode,
        });
      }
      const tableData = this.tableData.map((item) => {
        if (!item.onceContainerCode) {
          return {
            ...item,
            onceContainerCode: resp.shift().containerCode,
            assignedLocationCode: item.assignedLocationCode,
            recommendLocationCode: item.assignedLocationCode,
          };
        }
        return {
          ...item,
        };
      });
      this.tableData = [...tableData];
      const newTable = tableData.map((item) => ({
        ...item,
        amount: item.amount * this.manualRecommend.conversionRate,
      }));
      const data = {
        ...this.manualRecommend,
        details: newTable.map((item) => this.setDetails(item)),
      };
      await updateLocationAndContainer({ goodsDetails: [data] });
      newTable.forEach((item) => {
        tableLists.push({
          goodsName: this.manualRecommend.goodsName,
          shipperName: this.manualRecommend.shipperName,
          shipperGoodsCode: this.manualRecommend.shipperGoodsCode,
          thirdProCode: this.manualRecommend.thirdProCode,
          goodsSpecifications: this.manualRecommend.goodsSpecifications,
          temperatureLayerName: this.manualRecommend.temperatureLayerName,
          storeInTime: this.manualRecommend.storeInTime,
          productDate: this.manualRecommend.productDate,
          boxNo: this.manualRecommend.boxNo,
          palletRule: this.manualRecommend.palletRule,
          assignedLocationCode: item.assignedLocationCode,
          onceContainerCode: item.onceContainerCode,
          amount: unitNumber.calculate({ ...item, smallUnitNumber: item.amount }).txt,
          expireDate: this.manualRecommend.expireDate,
          customerBatch: this.manualRecommend.customerBatch,
        });
      });

      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists), printNum: 1 },
      });
      window.open(routeUrl.href, '_blank');
      this.handleClose();
    },
    // 设置详情信息
    setDetails(item) {
      const smallUnitNumber = unitNumber.getTotal({
        ...item, bigUnitNumber: item.veneerNum,
      });
      let storeUnit = STORE_UNIT.WHOLE_BRACKET;
      const { smallUnitNumber: smallNum } = unitNumber.calculate({
        ...item, smallUnitNumber: item.amount,
      });
      if (item.amount === smallUnitNumber) {
        storeUnit = STORE_UNIT.WHOLE_BRACKET;
      } else if (smallNum === 0
      || item.bigUnit === item.smallUnit) {
        storeUnit = STORE_UNIT.WHOLE_BOX;
      } else {
        storeUnit = STORE_UNIT.PART;
      }
      return {
        ...item,
        storeUnit,
      };
    },
    // 单个打印
    supplementaryPrinting(row) {
      const tableLists = [];
      tableLists.push({
        goodsName: this.manualRecommend.goodsName,
        shipperName: this.manualRecommend.shipperName,
        shipperGoodsCode: this.manualRecommend.shipperGoodsCode,
        thirdProCode: this.manualRecommend.thirdProCode,
        goodsSpecifications: this.manualRecommend.goodsSpecifications,
        temperatureLayerName: this.manualRecommend.temperatureLayerName,
        storeInTime: this.manualRecommend.storeInTime,
        productDate: this.manualRecommend.productDate,
        boxNo: this.manualRecommend.boxNo,
        palletRule: this.manualRecommend.palletRule,
        assignedLocationCode: row.assignedLocationCode,
        onceContainerCode: row.onceContainerCode,
        amount: unitNumber.calculate(
          { ...row, smallUnitNumber: row.amount * this.manualRecommend.conversionRate },
        ).txt,
        expireDate: this.manualRecommend.expireDate,
        customerBatch: this.manualRecommend.customerBatch,
      });
      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists), printNum: 1 },
      });
      window.open(routeUrl.href, '_blank');
      this.handleClose();
    },
  },
};
</script>
<style scoped lang="scss">
.goods-attr {
  margin: 0 10px;
  display: inline-block;
  min-width: 50px;

  & + .goods-attr {
    margin-left: 20px;
  }

  &:last-child {
    min-width: auto;
  }
}

.enter-num-box {
  display: flex;
  align-items: center;
}

.delete-btn {
  color: #f56c6c;
}

.footer-box {
  text-align: right;
  padding: 16px 24px;

  .cloose-btn {
    width: 74px;
    height: 32px;
  }
}

</style>
