<template>
  <nh-dialog
    v-model="visible"
    title="打印一次性容器码"
    custom-class="custom-dialog_560"
    :track="{
      name_zh: `${nameZh}抽屉-详情/弹窗-打印一次性容器`,
      trackName: $route.path
    }"
    @close="handleBeforeClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
    >
      <nh-dialog-form-item
        :span="24"
        label="打印份数"
        prop="num"
      >
        <el-input v-model.number="ruleForm.num" />
      </nh-dialog-form-item>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          :loading="loading.handleSubmit"
          type="primary"
          @click="handleSubmit"
        >
          确 定
        </nh-button>
        <nh-button
          type="grey"
          @click="handleClose"
        >
          关 闭
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';

export default {
  name: 'InboundOrderDetailTableOnce',
  mixins: [loadingMixin],
  inject: ['nameZh'],
  data() {
    const validateStart = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    };
    return {
      visible: false,
      loading: {
        handleSubmit: false,
      },
      ruleForm: {
        num: null,
      },
      rules: {
        num: [
          { required: true, validator: validateStart, trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    init() {
      this.visible = true;
    },
    async handleSubmit() {
      await this.$refs.ruleForm.validate();
      const routeUrl = this.$router.resolve({
        path: '/print/disposable-container',
        query: { endCode: this.ruleForm.num },
      });
      window.open(routeUrl.href, '_blank');
      this.handleClose();
    },
    handleBeforeClose() {
      this.$refs.ruleForm.resetFields();
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
