<template>
  <div>
    <!-- 货品明细 -->
    <div v-if="type === 1" class="goods-button">
      <el-select v-model="isAssignedLocation" class="goods-select-box">
        <el-option
          v-for="item in assignedLocationOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="copyCode" class="goods-select-box">
        <el-option
          v-for="item in copyCodeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <nh-button
        type="primary"
        @click="handleManualAssignment"
      >
        人工分配库位
      </nh-button>
      <nh-button
        type="primary"
        @click="handleOpenOnce"
      >
        打印一次性容器
      </nh-button>
      <nh-button
        v-loading="loading.handleOpenApportion"
        type="primary"
        @click="handleOpenApportion"
      >
        分配上架库位
      </nh-button>
      <nh-button
        v-loading="loading.handleOpenGoods"
        type="primary"
        @click="handleOpenGoods"
      >
        打印货品标签
      </nh-button>
    </div>
    <PlTable
      ref="tableData"
      v-loading="loading.getTableData"
      :data="screenGoods"
      :columns="TABLECOLUMN(type,isOnlyDetail, GOODS_QUALITY)"
      :show-summary="handleShowSummary()"
      :summary-method="handleGetSummaries"
      :show-overflow-tooltip="true"
      @selection-change="handleSelectChange"
    >
      <template #batchNo="{row}">
        <el-tooltip
          class="item"
          effect="dark"
          placement="top-start"
        >
          <template #content>
            批次号:{{ row.batchNo }}
            <p
              v-for="(batchField) in row.batchFields"
              :key="batchField.fieldCode"
              class="batch-field"
            >
              {{ batchField.fieldName }}:{{ batchField.paddingValue }}
            </p>
          </template>
          <nh-button type="text">
            {{ row.batchNo }}
          </nh-button>
        </el-tooltip>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.canCancel"
          type="text"
          :loading="loading.cancelAcceptance"
          @click="cancelAcceptance(row.id)"
        >
          取消验收
        </nh-button>
        <nh-button
          v-if="row.onceContainer"
          type="text"
          @click="handlePrintAcceptance(row)"
        >
          重打容器码
        </nh-button>
      </template>
    </PlTable>
    <Once ref="once" />
    <GoodsLabel ref="goodsLabel" />
    <Apportion ref="apportion" />
    <manual-assignment ref="manualAssignment" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import tableSummary from '@/utils/tableSummary';
import Once from './components/once.vue';
import GoodsLabel from './components/goodsLabel.vue';
import Apportion from './components/apportion.vue';
import manualAssignment from './components/manualAssignmentLocation.vue';
import { goodsList, cancelAcceptance } from '../api';
import {
  TABLECOLUMN,
} from './fileds';
import mixin from '../mixin';
import summaryConfig from './config';
import { URGENT } from '../constant';

export default {
  name: 'DetailTable',
  components: {
    Once, GoodsLabel, Apportion, manualAssignment,
  },
  filters: {
  },
  mixins: [loadingMixin, mixin],
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
    storeData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: () => 1,
    },
    isOnlyDetail: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['cancelAcceptance'],
  data() {
    return {
      TABLECOLUMN,
      selectData: [],
      isAssignedLocation: null,
      copyCode: null,
      loading: {
        handleOpenGoods: false,
        cancelAcceptance: false,
      },
    };
  },
  computed: {
    assignedLocationOptions() {
      return [{ label: '-是否已分配库位-', value: null }, ...URGENT];
    },
    copyCodeOptions() {
      return [{ label: '-是否抄码货品-', value: null }, ...URGENT];
    },
    screenGoods() {
      let list = [...this.goods];
      if (this.type === 1) {
        switch (this.isAssignedLocation) {
          case true:
            list = list.filter((item) => item.assignedLocation === this.isAssignedLocation);
            break;
          case false:
            list = list.filter((item) => item.assignedLocation === this.isAssignedLocation);
            break;
          default:
        }
        switch (this.copyCode) {
          case true:
            list = list.filter((item) => item.copyCode === this.copyCode);
            break;
          case false:
            list = list.filter((item) => item.copyCode === this.copyCode);
            break;
          default:
        }
      }
      return list;
    },
  },
  methods: {
    handleShowSummary() {
      return summaryConfig.has(this.type);
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig.get(this.type);
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    handleSelectChange(select) {
      this.selectData = select;
    },
    handleOpenOnce() {
      this.$refs.once.init();
    },

    async cancelAcceptance(id) {
      await this.$confirm('是否确认取消该验收记录？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await cancelAcceptance({ acceptanceDetailId: id });
      this.$message({ type: 'success', message: '取消验收成功！' });
      this.$emit('cancelAcceptance');
    },
    async handleOpenGoods() {
      if (this.selectData.length > 0) {
        const goodsIds = this.selectData.map((item) => item.goodsId);
        const goodsData = await goodsList({ goodsIds });
        const putTogether = this.selectData.map((item) => {
          const findGoods = goodsData.find((goods) => item.goodsId === goods.id);
          return {
            ...item,
            bookingNumTxt: unitNumber.calculate({
              ...item, smallUnitNumber: item.bookingNum,
            }).txt,
            veneerNum: findGoods?.storageRuleExt?.veneerNum || 0,
          };
        });
        this.$refs.goodsLabel.init(putTogether);
      }
    },
    handlePrintAcceptance(row) {
      const tableLists = [{
        goodsName: row.goodsName,
        shipperName: this.storeData.shipperName,
        shipperGoodsCode: row.shipperGoodsCode,
        thirdProCode: row.thirdProCode,
        goodsSpecifications: row.goodsSpecifications,
        temperatureLayerName: row.temperatureLayerName,
        storeInTime: this.storeData.bookingTime,
        productDate: row.productDate,
        boxNo: row.boxNo,
        palletRule: row.palletRule,
        assignedLocationCode: row.recommendStorehouseCode,
        onceContainerCode: row.containerCode,
        amount: unitNumber.calculate({ ...row, smallUnitNumber: row.acceptanceValue }).txt,
        expireDate: row.expireDate,
        customerBatch: row.customerBatch,
      }];
      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists) },
      });
      window.open(routeUrl.href, '_blank');
    },
    async handleOpenApportion() {
      if (this.selectData.length > 0) {
        this.$refs.apportion.init(this.selectData, this.storeData);
      } else {
        this.$message({
          message: '请选择要分配上架库位的货品',
          type: 'warning',
        });
      }
    },
    handleManualAssignment() {
      if (this.selectData.length === 1) {
        this.$refs.manualAssignment.init(this.selectData[0], this.storeData);
      } else {
        this.$message({
          message: '请选择一个要分配上架库位的货品',
          type: 'warning',
        });
      }
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}

.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

.placeholder.el-button--text,
.placeholder.el-button--text:focus,
.placeholder.el-button--text:visited {
  color: #606266;
  cursor: default;
}

.goods-button {
  float: right;
  padding-bottom: 8px;
}

.goods-select-box {
  width: 140px;
  display: inline-block;
  margin-right: 16px;
}
</style>
