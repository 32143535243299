import utils from '@/utils/unitNumber';
import { TEMPERATURE_LAYER_CODE_NEW_ENUM, URGENT_ENUM } from '../constant';
import { timeFormat } from '../utils';

const numberFotmat = (current, rowData) => {
  let result = '';
  if (current) {
    result = utils.calculate({
      bigUnit: rowData.bigUnit || '',
      smallUnit: rowData.smallUnit || '',
      smallUnitNumber: current,
      bigUnitNumber: 0,
      conversionRate: rowData.conversionRate,
    }).txt;
  } else {
    result = current;
  }

  return result;
};
function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = (type, isOnlyDetail, GOODS_QUALITY) => {
  const GOODS_BEFOR_COLUMN = [
    {
      prop: 'selection',
      type: 'selection',
      width: 50,
      align: 'center',
    }, {
      label: '序号',
      type: 'index',
      width: 50,
    },
    {
      label: '明细ID',
      prop: 'id',
      width: 180,
    }];
  const GOODS_AFTER_COLUMN = [{
    label: '验收数量',
    prop: 'acceptanceNum',
    width: 180,
    formatter: (row) => numberFotmat(row.acceptanceNum, row),

  }, {
    label: '上架数量',
    prop: 'upperShelfNum',
    width: 180,
    formatter: (row) => numberFotmat(row.upperShelfNum, row),

  }, {
    label: '是否抄码',
    prop: 'copyCode',
    width: 180,
    formatter: (row) => URGENT_ENUM[row.copyCode],

  }, {
    label: '是否已分配库位',
    prop: 'assignedLocation',
    width: 180,
    formatter: (row) => URGENT_ENUM[row.assignedLocation],

  }, {
    label: '备注',
    prop: 'ext.remark',
    width: 180,

  }];
  const GOODS_CENTER_COLUMN = [{
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    width: 180,
  }, {
    label: '商品名称',
    prop: 'goodsName',
    width: 180,
  }, {
    label: '规格',
    prop: 'spec',
    width: 180,
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    width: 180,
    formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER_CODE_NEW_ENUM[temperatureLayer],
  }, {
    label: '保质期(天)',
    prop: 'shelfLife',
    width: 180,
  }, {
    label: '是否越库',
    prop: 'crossDocking',
    width: 180,
    formatter: ({ crossDocking }) => (crossDocking ? '是' : '否'),
  }, {
    label: '批属性(预约)',
    prop: 'batchAttr',
    width: 180,
    formatter: ({ batchAttr }) => batchAttr.map((item) => item.attrName).join(','),

  },
  {
    label: '货品质量',
    prop: 'quality',
    width: 180,
    formatter: ({ quality }) => filterLabel(quality, GOODS_QUALITY),
  }, {
    label: '换算关系',
    prop: 'conversionRate',

  }, {
    label: '默认单位',
    prop: 'defaultUnit',
  }, {
    label: '预约数量(默认单位)',
    prop: 'defaultUnitBookingNum',
    width: 180,
  }, {
    label: '预约数量',
    prop: 'bookingNum',
    width: 180,
    formatter: (row) => numberFotmat(row.bookingNum, row),

  }];
  // 将 详情 和 到货登记的详情 合并为一个  ，到货登记的详情 直接调用 详情页面
  const GOODS_COLUMN = isOnlyDetail
    ? [...GOODS_BEFOR_COLUMN, ...GOODS_CENTER_COLUMN, ...GOODS_AFTER_COLUMN]
    : [...GOODS_CENTER_COLUMN];
  const TABLECOLUMN_INSIDE = {
    // 货品明细
    1: GOODS_COLUMN,
    // 验收详情
    2: [{
      label: '序号',
      type: 'index',
      width: 50,
    }, {
      label: '货主货品编码',
      prop: 'shipperGoodsCode',
      width: 180,
    }, {
      label: '商品名称',
      prop: 'goodsName',
      width: 180,
    }, {
      label: '批属性(预约)',
      prop: 'batchFields',
      width: 180,
      formatter: ({ batchFields }) => (batchFields ? batchFields.map((item) => item.fieldName).join(',') : ''),
    }, {
      label: '入库单明细ID',
      prop: 'taskId',
      width: 180,
    }, {
      label: '换算关系',
      prop: 'conversionRate',
      width: 180,
    }, {
      label: '默认单位',
      prop: 'defaultUnit',
    }, {
      label: '验收数量(默认单位)',
      prop: 'defaultUnitAcceptanceNum',
      width: 180,
    }, {
      label: '验收数量',
      prop: 'acceptanceValue',
      width: 180,
      formatter: (row) => numberFotmat(row.acceptanceValue, row),
    }, {
      label: '批次号',
      prop: 'batchNo',
      width: 180,

    }, {
      label: '生产日期',
      prop: 'productDate',
      width: 180,
      formatter: ({ productDate }) => timeFormat(productDate),

    }, {
      label: '验收容器',
      prop: 'containerCode',
      width: 180,

    }, {
      label: '货品质量',
      prop: 'quality',
      width: 180,
      formatter: ({ quality }) => filterLabel(quality, GOODS_QUALITY),
    }, {
      label: '验收人',
      prop: 'operatorStaffName',
      width: 180,

    }, {
      label: '验收时间',
      prop: 'operationTime',
      width: 180,
      formatter: ({ operationTime }) => timeFormat(operationTime),
    }, {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      width: 180,
    },
    ],
    // 入库详情
    3: [{
      label: '序号',
      type: 'index',
      width: 50,
    }, {
      label: '货主货品编码',
      prop: 'shipperGoodsCode',
      width: 180,
    }, {
      label: '商品名称',
      prop: 'goodsName',
      width: 180,
    }, {
      label: '入库单明细ID',
      prop: 'taskId',
      width: 180,
    }, {
      label: '换算关系',
      prop: 'conversionRate',
      width: 180,
    }, {
      label: '货品质量',
      prop: 'quality',
      width: 180,
      formatter: ({ quality }) => filterLabel(quality, GOODS_QUALITY),
    }, {
      label: '验收数量',
      prop: 'acceptanceValue',
      width: 180,
      formatter: (row) => numberFotmat(row.acceptanceValue, row),
    }, {
      label: '批次号',
      prop: 'batchNo',
      width: 180,

    }, {
      label: '生产日期',
      prop: 'productDate',
      width: 180,
      formatter: ({ productDate }) => timeFormat(productDate),
    }, {
      label: '验收容器',
      prop: 'containerCode',
      width: 180,

    },
    {
      label: '货品质量',
      prop: 'quality',
      width: 180,
      formatter: ({ quality }) => filterLabel(quality, GOODS_QUALITY),
    }, {
      label: '入库暂存位',
      prop: 'tempStorehouseCode',
      width: 180,

    }, {
      label: '入库人',
      prop: 'operatorStaffName',
      width: 180,

    }, {
      label: '入库时间',
      prop: 'operationTime',
      width: 180,
      formatter: ({ operationTime }) => timeFormat(operationTime),
    },
    ],
    // 上架详情
    4: [{
      label: '序号',
      type: 'index',
      width: 50,
    }, {
      label: '货主货品编码',
      prop: 'shipperGoodsCode',
      width: 180,
    }, {
      label: '商品名称',
      prop: 'goodsName',
      width: 180,
    }, {
      label: '入库单明细ID',
      prop: 'taskId',
      width: 180,
    }, {
      label: '换算关系',
      prop: 'conversionRate',
      width: 180,
    }, {
      label: '货品质量',
      prop: 'goodsQuality',
      width: 180,
      formatter: ({ goodsQuality }) => filterLabel(goodsQuality, GOODS_QUALITY),
    }, {
      label: '批次号',
      prop: 'batchNo',
      width: 180,
    }, {
      label: '生产日期',
      prop: 'productDate',
      width: 180,
      formatter: ({ productDate }) => timeFormat(productDate),
    }, {
      label: '上架库位',
      prop: 'onloadStorehouseCode',
      width: 180,

    }, {
      label: '上架数量',
      prop: 'upperShelfNum',
      width: 180,
      formatter: (row) => numberFotmat(row.upperShelfNum, row),

    }, {
      label: '上架容器',
      prop: 'containerCode',
      width: 180,

    },
    {
      label: '货品质量',
      prop: 'goodsQuality',
      width: 180,
      formatter: ({ goodsQuality }) => filterLabel(goodsQuality, GOODS_QUALITY),
    }, {
      label: '上架人',
      prop: 'operatorStaffName',
      width: 180,

    }, {
      label: '上架时间',
      prop: 'operationTime',
      width: 180,
      formatter: ({ operationTime }) => timeFormat(operationTime),
    },
    ],
  };
  return TABLECOLUMN_INSIDE[type] || [];
};

export const TABLECOLUMN_1 = [{
  label: '明细ID',
  prop: 'goodsCode',
}, {
  label: '货品编码',
  prop: 'goodsName',
}, {
  label: '商品名称',
  prop: 'temperatureLayer',
}, {
  label: '规格',
  prop: 'batchNo',
}, {
  label: '温层',
  prop: 'spec',
}, {
  label: '保质期(天)',
  prop: 'bookingNum',
}, {
  label: '是否越库',
  prop: 'deliverNum',

}, {
  label: '批属性(预约)',
  prop: 'deliverNum1',

}, {
  label: '换算关系',
  prop: 'conversionRate',

}, {
  label: '预约数量',
  prop: 'deliverNum3',

}, {
  label: '验收数量',
  prop: 'deliverNum4',

}, {
  label: '入库数量',
  prop: 'deliverNum5',

}, {
  label: '上架数量',
  prop: 'deliverNum6',

},
];
