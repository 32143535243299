import { formatKeyValueObject } from '@/utils/base';
import unitNumber from '@/utils/unitNumber';

const DICTIONARY = {
  SINGLE: 'single',
  MORE: 'more',
  PRODUCT_CODES: 'GOODS',
  BATCH_CODES: 'BATCH',
  BOX_CODES: 'BOX',
  APPOINT_BOX: 'appointmentBox',
  ESTIMATE_PLANK: 'estimatePlank',
};

const printLabel = [{
  label: '单品',
  value: DICTIONARY.SINGLE,
}, {
  label: '多品',
  value: DICTIONARY.MORE,
}];
const PRINT_LABEL_ENUM = formatKeyValueObject(printLabel);
function labelRadio(titleValue) {
  if (DICTIONARY.SINGLE === titleValue) {
    return [{
      label: '货品码',
      value: DICTIONARY.PRODUCT_CODES,
    }, {
      label: '货品批次码',
      value: DICTIONARY.BATCH_CODES,
    }, {
      label: '箱条码',
      value: DICTIONARY.BOX_CODES,
    }];
  }
  return [{
    label: '货品码',
    value: DICTIONARY.PRODUCT_CODES,
  }, {
    label: '箱条码',
    value: DICTIONARY.BOX_CODES,
  }];
}
const printType = [{
  label: '按预约箱数',
  value: DICTIONARY.APPOINT_BOX,
}, {
  label: '按预估板数',
  value: DICTIONARY.ESTIMATE_PLANK,
}];
export {
  DICTIONARY, printLabel, labelRadio, printType,
  PRINT_LABEL_ENUM,
};
export const STORE_UNIT = {
  PART: 'PART', // 拆零
  WHOLE_BOX: 'WHOLE_BOX', // 整箱
  WHOLE_BRACKET: 'WHOLE_BRACKET', // 整托
};
function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const DETAILS = ['amount', 'recommendLocationCode', 'assignedLocationCode', 'onceContainerCode'];
export const FORM_APPORTION_FILED = (GOODS_QUALITY) => [
  {
    label: '货主货品编码:',
    prop: 'shipperGoodsCode',
    width: 180,
  }, {
    label: '货品名称:',
    prop: 'goodsName',
    width: 180,
  }, {
    label: '货主:',
    prop: 'shipperName',
    width: 120,
  }, {
    label: '预约数量:',
    prop: 'bookingNum',
    width: 120,
    formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.bookingNum }).txt,
  }, {
    label: '待验收数量:',
    prop: 'preAcceptanceNum',
    width: 120,
    formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.preAcceptanceNum }).txt,

  }, {
    label: '指定货品质量:',
    prop: 'goodsQuality',
    minWidth: 120,
    formatter: (row) => filterLabel(row.goodsQuality, GOODS_QUALITY),
  },
];
export const APPORTION_TABLECOLUMN = [{
  label: '数量',
  prop: 'amount',
  formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.amount }).txt,
  minWidth: 120,
  headerAlign: 'center',
  align: 'center',
}, {
  label: '系统推荐库位',
  prop: 'recommendLocationCode',
  minWidth: 140,
  headerAlign: 'center',
  align: 'center',
  formatter: (row) => row.recommendLocationCode || '-',
}, {
  label: '手动指定库位',
  prop: 'assignedLocationCode',
  minWidth: 140,
  headerAlign: 'center',
  align: 'center',
}, {
  label: '一次性容器码',
  prop: 'onceContainerCode',
  minWidth: 160,
  headerAlign: 'center',
  align: 'center',
  formatter: (row) => row.onceContainerCode || '-',
},
{
  label: '操作',
  prop: 'action',
  fixed: 'right',
  minWidth: 100,
  headerAlign: 'center',
  align: 'center',
},
];
