<template>
  <nh-detail-page>
    <nh-container title="订单基本信息">
      <nh-dialog-form label-width="120px">
        <nh-dialog-form-item
          v-for="item in DetailFromInfo(showDock,StoreData)"
          :key="item.label"
          :label="item.label"
          :span="6"
        >
          <template #default>
            <span v-if="item.formatter">{{ item.formatter(StoreData[item.prop]) }}</span>
            <span v-else class="inbound-detail-span">{{ StoreData[item.prop] }}</span>
          </template>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="备注:" :span="24">
          <p>{{ StoreData['remark'] }}</p>
        </nh-dialog-form-item>
        <el-form-item
          label=""
          prop="files"
          label-width="0"
          :span="24"
          class="files-form"
        >
          <nh-upload-attachment
            v-model:upload-file="fileData"
            :disabled="true"
            :uploads-style="{
              padding: '12px 0',
              backgroundColor: '#f6f8fa',
              borderRadius: '4px',
            }"
          />
        </el-form-item>
      </nh-dialog-form>
    </nh-container>
    <nh-container>
      <el-tabs>
        <el-tab-pane label="货品明细">
          <DetailTable
            :goods="StoreData.goods"
            :type="1"
            :store-data="StoreData"
            :is-only-detail="isOnlyDetail"
          />
        </el-tab-pane>
        <el-tab-pane v-if="isOnlyDetail" label="验收详情">
          <DetailTable
            :goods="StoreData.acceptanceDetails"
            :type="2"
            :store-data="StoreData"
            @cancelAcceptance="loadInboundOrderDetail"
          />
        </el-tab-pane>
        <el-tab-pane
          v-if="isShowInboundOrderTable(StoreData.isMove,isOnlyDetail)"
          label="入库详情"
        >
          <DetailTable :goods="StoreData.storeDetail" :type="3" />
        </el-tab-pane>
        <el-tab-pane v-if="isOnlyDetail" label="上架详情">
          <DetailTable :goods="StoreData.upperShelfDetail" :type="4" />
        </el-tab-pane>
      </el-tabs>
    </nh-container>
  </nh-detail-page>
</template>
<script>
import loadingMixin from '@thales/loading';
import DetailTable from '../detailTable/detailTable.vue';
import { inboundOrderDetail } from '../api';
import DetailFromInfo from './detailForm';
import { timeFormat } from '../utils';

export default {
  name: 'InboundOrderDetail',
  components: {
    DetailTable,
  },
  mixins: [loadingMixin],
  props: {
    isOnlyDetail: {
      type: Boolean,
      default: () => true,
    },
    inboundOrderId: {
      type: Number,
      default: null,
    },
    receiptOrderNo: {
      type: [Number, String],
      default: null,
    },
    showDock: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['fileData', 'storeData'],
  data() {
    return {
      DetailFromInfo,
      StoreData: {
      },
      fileData: [],
      loading: {
        loadInboundOrderDetail: false,
      },
    };
  },
  watch: {
    inboundOrderId: {
      handler() {
        this.loadInboundOrderDetail();
      },
      deep: true,
    },
  },
  created() {
    this.loadInboundOrderDetail();
  },
  methods: {
    async loadInboundOrderDetail() {
      const result = await inboundOrderDetail({
        receiptOrderId: this.$route.query.id || this.inboundOrderId,
        receiptOrderNo: this.receiptOrderNo,
        needAcceptance: true,
        needUpperShelf: true,
        needStore: true,
      });
      this.StoreData = result;
      this.fileData = result.files.map((item) => ({
        ...item, url: item.filePath, fileName: item.fileName, fileTime: timeFormat(item.updateTime),
      }));
      this.$emit('fileData', this.fileData);
      this.$emit('storeData', this.StoreData);
    },
    isShowInboundOrderTable(isMove, isOnlyDetail) {
      if (!isOnlyDetail) {
        return false;
      }
      return isMove;
    },
  },
};
</script>
<style lang="scss" scoped>
.inbound-detail-span {
  word-break: break-all;
}

.block {
  .el-form-item {
    margin: 0;
  }
}

.title {
  border-left: 3px solid #02a7f0;
  padding-left: 10px;

  h3 {
    font-size: 14px;
  }
}

.store_info {
  margin-top: 20px;
}

.body {
  padding-left: 40px;
  width: calc(100% - 40px);
}

.sub_title {
  margin-top: 20px;
  padding: 4px 32px 4px 4px;
  display: inline-block;
  transform: skew(-25deg);
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  background-color: #f2f2f2;

  h4 {
    font-weight: normal;
    transform: skew(25deg);
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    -ms-transform: skew(25deg);
  }
}

.files-form {
  width: 100%;
}

</style>
