import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 查询货品库位和一次性容器详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16485
 */
export function locationDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/location_detail', data);
}
/**
 * @description: 保存推荐库位，一次性库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16487
 */
export function updateLocation(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/update_location_and_container', data);
}
/**
 * @description: 上架规则 推荐库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16473
 */
export function recommendStorehouse(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_upper/recommend/storehouse', data);
}
/**
 * @description: 一次性容器批量创建
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12182
 */
export function batchInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/container/batch/insert', data);
}
/**
 * @description: 库位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11180
 */
export function storehousePage(data, params = {
  page: 1,
  size: 10,
}) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data, {
    params,
  });
}
/**
 * @description: 有空库位的巷道号列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16913
 */
export function emptyLocationRows(data) {
  return wmsPlusHttp.post('/warehouse_management_system/storehouse/empty_location/rows', data);
}
/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12006
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
/**
 * @description: 手动推荐库位查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17041
 */
export function manualRecommendQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/manual_recommend_location_and_container/query', data);
}
/**
 * @description: 手工指定推荐库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17037
 */
export function updateLocationAndContainer(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/manual/update_location_and_container', data);
}
/**
 * @description: 删除推荐数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17039
 */
export function locationAndContainerDelete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/manual_recommend_location_and_container/delete', data);
}
/**
 * @description: 通过库区过滤层
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/17059
 */
export function queryLayer(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/query/layer', data);
}
/**
 * @description: 通过库区巷道层过滤库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/17061
 */
export function storehouseQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/manual_recommend/empty_location/query', data);
}
