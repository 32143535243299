<template>
  <nh-drawer
    v-model="visible"
    title="指定上架库位"
    size="1111px"
    :before-close="handleClose"
    :track="{
      name_zh: `${nameZh}抽屉-详情/抽屉-分配上架库位`,
      trackName: $route.path
    }"
  >
    <div v-loading="loading.init" class="multipleTable-box">
      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="multipleTable"
      >
        <el-checkbox v-model="item.checked" class="multipleTable-checkbox" />
        <nh-dialog-form :ref="'form'+index" :model="item">
          <nh-dialog-form-item
            v-for="it in FORM_APPORTION_FILED(GOODS_QUALITY)"
            :key="it.prop"
            :label="it.label"
            :span="8"
          >
            <span v-if="it.formatter">{{ it.formatter(item) }}</span>
            <span v-else>{{ item[it.prop] }}</span>
          </nh-dialog-form-item>
        </nh-dialog-form>
        <div class="enter-num-box">
          <div style="width: 160px;">
            单板数量：
          </div>
          <el-input
            v-model="item.veneerNum"
            @input="(val) => item.veneerNum = Number(val.replace(/[^0-9]/ig,'')) || 1"
            @change="(val)=>handleVeneerChange(val,item.receiptOrderDetailId)"
          />
          <span style="margin-left: 8px;">{{ item.bigUnit }}</span>
        </div>
        <div :class="item.showMore?'':'multiple-table-box'">
          <PlTable
            ref="multipleTable"
            :data="item.details"
            :columns="APPORTION_TABLECOLUMN"
            :loading="loading.handleVeneerChange"
            cell-style="height: 46px;padding: 0;"
          >
            <template #amount="{row}" class="margin-bottom0">
              <div>
                <span>{{ unitNumber.calculate({ ...item,
                smallUnitNumber: row.amount }).txt }}</span>
              </div>
            </template>
            <template #assignedLocationCode="{row}" class="margin-bottom0">
              <div>
                <el-autocomplete
                  v-model="row.assignedLocationCode"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入"
                  @input="row.change = true"
                />
              </div>
            </template>
            <template #action="{row}" class="margin-bottom0">
              <div>
                <nh-button
                  v-if="row.onceContainerCode"
                  type="text"
                  @click="supplementaryPrinting(item,row)"
                >
                  补打
                </nh-button>
              </div>
            </template>
          </PlTable>
        </div>
        <div v-if="item.details.length > 3" class="show-btn-box">
          <nh-button type="text" @click="item.showMore =!item.showMore">
            <span v-if="item.showMore">
              <i class="iconfont iconxiangxiazhankai-xianxingyuankuang-copy" />
              收起数据
            </span>
            <span v-else>
              <i class="iconfont iconxiangxiazhankai-xianxingyuankuang" />

              展开更多数据
            </span>
          </nh-button>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="operation-area">
        <div class="tips-box">
          <i class="iconfont icon-yichang" />指定时以指定的库区或巷道为准，不指定时以货品绑定的规则为准
        </div>
        <div class="operation-area-box">
          <el-form
            ref="formInline"
            :inline="true"
            :model="formInline"
            label-width="75px"
          >
            <el-form-item label="指定库区" prop="designatedZoneIds">
              <el-select
                v-model="formInline.designatedZoneIds"
                filterable
                clearable
                @change="warehouseZoneChange"
              >
                <el-option
                  v-for="(item, index) in warehouseZonelist"
                  :key="index"
                  :label="`${item.zoneCode} - ${item.zoneName}`"
                  :value="item.id"
                >
                  <div class="goods-attr-box">
                    <span class="goods-attr">{{ item.zoneCode }}</span>
                    <span class="goods-attr">{{ item.zoneName }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="指定巷道" prop="designatedRows">
              <el-select
                v-model="formInline.designatedRows"
                filterable
                multiple
                value-key="index"
                @change="changeRows"
              >
                <el-option
                  v-for="(item,index) in locationRows"
                  :key="index"
                  :label="item.zoneCode+item.row"
                  :value="item"
                >
                  <div class="goods-attr-box">
                    <span class="goods-attr">{{ item.zoneCode }}</span>
                    <span class="goods-attr">{{ item.row }}</span>
                    <span class="goods-attr">{{ item.emptyLocationCount }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <nh-button
                type="primary"
                :loading="loading.handleRecommendStorehouse"
                @click="handleRecommendStorehouse"
              >
                推荐库位
              </nh-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="footer-box">
        <div>
          <span class="print-num-label">打印份数</span>
          <el-input
            v-model="num"
            class="print-num"
            @input="(val) => num = Number(val.replace(/[^0-9]/ig,'')) || 1"
          />
        </div>
        <div>
          <nh-button
            type="grey"
            class="cloose-btn"
            @click="handleClose"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            :loading="loading.handlePrint"
            @click="handlePrint"
          >
            批量打印容器码
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import unitNumber from '@/utils/unitNumber';
import loadingMixin from '@thales/loading';
import {
  APPORTION_TABLECOLUMN,
  DETAILS, STORE_UNIT,
  FORM_APPORTION_FILED,
} from './constant';
import { TABLECOLUMN } from './fileds';
import {
  locationDetail, recommendStorehouse, updateLocation,
  batchInsert, storehousePage, emptyLocationRows, warehouseZonePage,
} from '../api';

export default {
  name: 'InboundOrderDetailTableApportion',
  mixins: [loadingMixin],
  inject: ['nameZh'],
  data() {
    return {
      FORM_APPORTION_FILED,
      APPORTION_TABLECOLUMN,
      TABLECOLUMN,
      unitNumber,
      DETAILS,
      visible: false,
      checkAll: false,
      formInline: {
        designatedZoneIds: undefined,
        designatedRows: [],
      },
      num: 1,
      storeData: {},
      locationRowsList: [],
      warehouseZonelist: [],
      tableData: [],
      rowsList: [],
      loading: {
        init: false,
        handleRecommendStorehouse: false,
        handlePrint: false,
        handleVeneerChange: false,
      },
    };
  },
  computed: {
    locationRows() {
      return this.locationRowsList.map((item, index) => ({
        ...item,
        index,
      }));
    },
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    // 单板数改变
    async handleVeneerChange(val, receiptOrderDetailId) {
      this.$message({
        message: '调整单板数量后请重新打印一次性容器码',
        type: 'warning',
      });
      const tableData = await this.tableData.map((item) => {
        const newItem = item;
        if (newItem.receiptOrderDetailId === receiptOrderDetailId) {
          newItem.veneerNum = val;
          const details = this.setDetails(newItem).map((detail) => ({
            ...detail,
            onceContainerCode: null,
          }));
          return {
            ...newItem,
            details,
          };
        }
        return newItem;
      });
      this.tableData = [...tableData];
    },
    // 设置详情信息
    setDetails(item) {
      const details = [];
      const smallUnitNumber = unitNumber.getTotal({
        ...item, bigUnitNumber: item.veneerNum,
      });
      const rowNum = smallUnitNumber ? Math.ceil(item.preAcceptanceNum / smallUnitNumber) : 0;
      for (let i = 0; i < rowNum; i += 1) {
        let amount = smallUnitNumber;
        let storeUnit = STORE_UNIT.WHOLE_BRACKET;
        if (i === rowNum - 1) {
          amount = item.preAcceptanceNum % smallUnitNumber || smallUnitNumber;
          const { smallUnitNumber: smallNum } = unitNumber.calculate({
            ...item, smallUnitNumber: amount,
          });
          if (amount === smallUnitNumber) {
            storeUnit = STORE_UNIT.WHOLE_BRACKET;
          } else if (smallNum === 0 || item.bigUnit === item.smallUnit) {
            storeUnit = STORE_UNIT.WHOLE_BOX;
          } else {
            storeUnit = STORE_UNIT.PART;
          }
        }
        details.push({
          amount,
          recommendLocationCode: null,
          assignedLocationCode: null,
          onceContainerCode: null,
          storeUnit,
        });
      }
      return details;
    },
    async init(rows, storeData) {
      this.visible = true;
      this.storeData = storeData;
      const receiptOrderDetailIds = rows.map((item) => item.id);
      const data = await locationDetail({ receiptOrderDetailIds });
      const tableList = data.map((item) => {
        const newItem = item;
        newItem.checked = true;
        if (!item.details || item.details.length < 1) {
          return {
            ...newItem,
            details: this.setDetails(newItem),
          };
        }
        return newItem;
      });
      this.tableData = [...tableList];
      this.getEmptyLocationRows();
      this.getSelectList();
    },
    // 获取库区
    async getSelectList() {
      const resp = await warehouseZonePage({ page: 1, size: 1000 },
        { warehouseCode: this.storeData.warehouseCode });
      this.warehouseZonelist = resp.records;
    },
    // 有空库位的巷道号列表
    async getEmptyLocationRows() {
      this.locationRowsList = await emptyLocationRows({
        zoneIds: this.formInline.designatedZoneIds
          ? [this.formInline.designatedZoneIds] : [],
      });
    },
    // 库区改变
    warehouseZoneChange() {
      this.getEmptyLocationRows();
    },
    changeRows(val) {
      this.rowsList = val.map((item) => ({
        row: item.row,
        zoneId: item.zoneId,
      }));
    },
    // 搜索库位
    async querySearchAsync(queryString, cb) {
      const { records } = await storehousePage({ storageLocationCode: queryString }) || [];
      const results = records.map((item) => ({
        value: item.storageLocationCode,
      }));
      cb(results);
    },
    // 推荐库位
    async handleRecommendStorehouse() {
      if (this.tableData.every((item) => !item.checked)) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
        return;
      }
      const selectTable = [];
      this.tableData.forEach((item) => {
        if (item.checked) {
          selectTable.push(item);
        }
      });
      const receiptOrderDetailIdList = selectTable.map((item) => item.receiptOrderDetailId);
      const goodsInfos = [];
      let flag = false;
      selectTable.forEach((item) => {
        const { goodsId, goodsQuality } = item;
        const { orderType = 'ORDER' } = item;

        item.details.forEach((list) => {
          if (list.recommendLocationCode) {
            flag = true;
          }
          goodsInfos.push({
            goodsId,
            goodsQuality,
            orderType,
            storeUnit: list.storeUnit,
          });
        });
      });
      if (flag) {
        await this.$confirm('已选择的货品中包含已推荐库位的货品行，是否要重新推荐库位？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
      }
      const data = await recommendStorehouse({
        goodsInfos,
        designatedZoneIds: this.formInline.designatedZoneIds
          ? [this.formInline.designatedZoneIds] : [],
        designatedRows: this.rowsList,
      });
      const tableData = this.tableData.map((item) => {
        const details = item.details.map((detail) => {
          let { assignedLocationCode, recommendLocationCode } = detail;
          if (receiptOrderDetailIdList.includes(item.receiptOrderDetailId)) {
            const code = data.shift().locationCode;
            recommendLocationCode = code;
            if (detail.change && !!detail.assignedLocationCode) {
              assignedLocationCode = detail.assignedLocationCode;
            } else {
              assignedLocationCode = code || detail.assignedLocationCode;
            }
          }
          return {
            ...detail,
            recommendLocationCode,
            assignedLocationCode,
          };
        });
        return {
          ...item,
          details,
        };
      });
      this.tableData = [...tableData];
    },
    async handlePrint() {
      let endCode = 0;
      const tableLists = [];
      this.tableData.forEach((item) => {
        item.details.forEach((detail) => {
          if (!detail.onceContainerCode) {
            endCode += 1;
          }
        });
      });
      let resp = [];
      if (endCode) {
        resp = await batchInsert({
          prefix: '',
          containerType: 'ONCE',
          containerAttr: 'BELONG',
          batchMax: 999999,
          startCode: 1,
          blendedBatch: '是',
          endCode,
        });
      }
      const tableData = this.tableData.map((item) => {
        const details = item.details.map((detail) => {
          if (!detail.onceContainerCode) {
            return {
              ...detail,
              onceContainerCode: resp.shift().containerCode,
              assignedLocationCode: detail.assignedLocationCode || detail.recommendLocationCode,
            };
          }
          return detail;
        });
        return {
          ...item,
          details,
        };
      });
      await updateLocation({ goodsDetails: tableData });
      tableData.forEach((item) => {
        item.details.forEach((detail) => {
          tableLists.push({
            goodsName: item.goodsName,
            shipperName: item.shipperName,
            shipperGoodsCode: item.shipperGoodsCode,
            thirdProCode: item.thirdProCode,
            goodsSpecifications: item.goodsSpecifications,
            temperatureLayerName: item.temperatureLayerName,
            storeInTime: item.storeInTime,
            productDate: item.productDate,
            boxNo: item.boxNo,
            palletRule: item.palletRule,
            assignedLocationCode: detail.assignedLocationCode,
            onceContainerCode: detail.onceContainerCode,
            amount: unitNumber.calculate({ ...item, smallUnitNumber: detail.amount }).txt,
            expireDate: item.expireDate,
            customerBatch: item.customerBatch,
          });
        });
      });

      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists), printNum: this.num },
      });
      window.open(routeUrl.href, '_blank');
      this.handleClose();
    },
    supplementaryPrinting(item, row) {
      const tableLists = [];
      tableLists.push({
        goodsName: item.goodsName,
        shipperName: item.shipperName,
        shipperGoodsCode: item.shipperGoodsCode,
        thirdProCode: item.thirdProCode,
        goodsSpecifications: item.goodsSpecifications,
        temperatureLayerName: item.temperatureLayerName,
        storeInTime: item.storeInTime,
        productDate: item.productDate,
        boxNo: item.boxNo,
        palletRule: item.palletRule,
        assignedLocationCode: row.assignedLocationCode,
        onceContainerCode: row.onceContainerCode,
        amount: unitNumber.calculate({ ...item, smallUnitNumber: row.amount }).txt,
        expireDate: item.expireDate,
        customerBatch: item.customerBatch,
      });
      const routeUrl = this.$router.resolve({
        path: '/print/location-and-container',
        query: { tableLists: JSON.stringify(tableLists), printNum: 1 },
      });
      window.open(routeUrl.href, '_blank');
      this.handleClose();
    },
    handleClose() {
      this.$refs.formInline.resetFields();
      this.rowsList = [];
      this.tableData = [];
      this.num = 1;
      this.visible = false;
    },
    arraySpanMethod({ row, rowIndex, columnIndex }) {
      if (columnIndex < 8) {
        if (rowIndex === row.mergeNum) {
          return [row.lengthNum, 1];
        }
        return [0, 0];
      }
      return [1, 1];
    },
  },
};
</script>
<style scoped lang="scss">
.multiple-table-box {
  max-height: 180px;
  overflow: hidden;
}

.multipleTable {
  position: relative;
  border-top: 1px solid #ebeef5;
  margin-top: 24px;
  padding-top: 16px;

  .multipleTable-checkbox {
    display: inline-block;
    line-height: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.multipleTable-box {
  .multipleTable:first-child {
    border: none;
    margin-top: 0;
    padding-top: 0;
  }
}

.iconxiangxiazhankai-xianxingyuankuang-copy,
.iconxiangxiazhankai-xianxingyuankuang {
  color: #000;
}

.print-num-label {
  width: 75px;
  padding-right: 16px;
  font-size: 14px;
}

.enter-num-box {
  display: flex;
  align-items: center;
  width: 210px;
  margin-bottom: 8px;
}

.operation-area {
  padding: 0 24px;
  margin-top: 24px;

  .tips-box {
    text-align: left;
    color: #fe5e08;

    i {
      color: #fe5e08;
      margin-right: 10px;
    }
  }

  .operation-area-box {
    margin-top: 24px;
    border-bottom: 1px solid #dcdfe6;
  }
}

.footer-box {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  .cloose-btn {
    width: 74px;
    height: 32px;
  }
}

.print-num {
  width: 218px;
  margin-right: 24px;
}

.goods-attr {
  margin: 0 10px;
  display: inline-block;
  min-width: 50px;

  & + .goods-attr {
    margin-left: 20px;
  }

  &:last-child {
    min-width: auto;
  }
}

.show-btn-box {
  text-align: center;
  font-size: 14px;
}

</style>
<style lang="scss">
.multipleTable-box {
  .el-form-item .el-form-item__label,
  .el-form-item .el-form-item__content {
    line-height: 16px;
  }
}

</style>
